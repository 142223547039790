<template>
  <router-link :to="{ name: 'share', params: { id: share.id } }" class="share-card hover-image">
    <ImgComponent :img="share.head_img" class="share-card__image" />
    <div class="share-card__text">
      <span class="share-card__text_discount">-{{ share.percent }}%</span>
      <span class="share-card__text_title">{{ share.title }}</span>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "ShareCardComponent",
  props: {
    share: {
      type: Object,
    },
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
.share-card{
  height: 263px;
  border-radius: 20px;
  display flex
  width: 100%;
  flex-direction column
  justify-content: flex-end;

  &__image{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit cover
    filter: brightness(80%);
  }

  &__text{
    display flex
    padding 20px
    flex-direction column
    position absolute
    gap 10px
    z-index: 11;

    &_discount{
      border-radius: 5px;
      width: fit-content;
      padding 5px 10px
      background-color var(--yellow)
      font-weight: 900;
      font-size: 1.75em
      line-height: 34px;
      text-transform: uppercase;
      color: var(--dark);
      +below(1024px){
        font-size: 1.25em;
      }
    }

    &_title{
      font-weight: 700;
      font-size: 1.375em;
      line-height: 26px;
      color: var(--white);
      +below(1024px){
        font-size: 1.125em;
      }
    }
  }
}
</style>
