<template>
  <div class="share-networks">
    <span class="share-networks__title">Поделиться</span>
    <div class="share-networks__list">
      <ShareNetwork
        v-for="(item, i) in socials"
        :key="i"
        :network="item.network"
        :url="shareUrl"
        :title="data.title"
      >
        <span :title="item.title" class="share-networks__item">
          <IconComponent :name="item.icon" />
        </span>
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ShareNetworksComponent",
  components: { IconComponent },
  props: {
    data: Object,
    isModal: Boolean,
    originUrl: String, // ссылка на страницу, если нужно чтобы ссылалось на нее, вместо текущей
  },
  data() {
    return {
      done: false,
      socials: [
        {
          title: "Telegram",
          network: "telegram",
          icon: "telegram",
        },
        {
          title: "Whatsapp",
          network: "whatsapp",
          icon: "whatsapp",
        },
        {
          title: "Vkontakte",
          network: "vk",
          icon: "vk",
        },
        // {
        //   title: "Twitter",
        //   network: "twitter",
        //   icon: "twitter",
        // },
      ],
    };
  },
  computed: {
    // ссылка которой мы делимся, если это модалка, то ссылка на внутреннюю страницу
    // иначе просто текущая ссылка
    shareUrl() {
      return this.$store.state._env.MEDIA_ENDPOINT + (this.originUrl || this.$route.fullPath);
    },
  },
  methods: {
    // копирование текущей ссылки
    async copyUrl() {
      try {
        await navigator.clipboard.writeText(location.href);
      } catch (err) {
        let inp = document.createElement("input");
        inp.value = location.href;
        document.body.appendChild(inp);
        inp.select();
        document.execCommand("copy");
        document.body.removeChild(inp);
      }
      this.done = true;
      setTimeout(() => {
        this.done = false;
      }, 1000);
    },
  },
};
</script>

<style lang="stylus">
.share-networks {
  display flex
  align-items center
  justify-content flex-end
  gap: 15px

  &__title {
    display flex
    font-size: 1.125em
    line-height: 28px;
    color: var(--dark-light)
  }

  &__list {
    display flex
    flex-direction row
    gap: 5px
  }

  &__item {
    display flex
    align-items center
    justify-content center
    border-radius 50%
    width 40px
    height: 40px
    border 1px solid var(--gray-dark)
    transition border-color var(--transition)

    &:hover {
      border-color var(--main)

      .file-icon svg path {
        fill var(--main)
      }
    }

    .icon {
      width: 16px
      height: 16px
    }
  }
}
</style>
