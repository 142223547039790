<template>
  <div class="share-description">
    <ShareCardComponent :share="share" />
    <div class="share-description__right">
      <EditorJSComponent
        v-if="share.description"
        :text="JSON.parse(share.description)"
        class="share-description__title"
      />
      <ShareNetworksComponent :data="share" />
    </div>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import ShareCardComponent from "components/ShareCardComponent.vue";
import ShareNetworksComponent from "components/ShareNetworksComponent.vue";

export default {
  name: "SharesDescriptionComponent",
  props: {
    share: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ShareNetworksComponent,
    ShareCardComponent,
    EditorJSComponent,
  },
};
</script>

<style lang="stylus">
.share-description{
  display grid
  grid-template-columns 554fr 388fr
  gap 30px

  +below(1024px){
    grid-template-columns 1fr
  }

  &__right{
    display flex
    flex-direction column
    gap 30px
    +below(1024px){
      gap 15px
    }

    //&__title {
    //  font-weight: 400;
    //  font-size: 1.125em;
    //  line-height: 28px;
    //  color: var(--dark);
    //  +below(1024px){
    //    font-size: 1em;
    //    line-height: 26px;
    //  }
    //}
  }
  &__right-buttons{
    display flex
    gap 15px
    align-items: center;
    span{
      color: var(--dark-light);
    }
    &_socials{
      display flex
      gap 5px
    }
  }

  .share-card{
    height: 473px;
    +below(1024px){
      height: 234px;
    }
  }
}
</style>
