<template>
  <div class="banners-slider">
    <SliderComponent :items="items" v-slot="{ item }" :slider-options="bannersSliderOptions" shift-arrows>
      <a :href="item.url" target="_blank" class="banners-slider__img" rel="nofollow">
        <div class="ratio-container">
          <ImgComponent :img="isMobile ? item.mobile_img : item.img" :loader="false" />
        </div>
      </a>
    </SliderComponent>
  </div>
</template>

<script>
import SliderComponent from "components/slider/SliderComponent.vue";

export default {
  name: "BannersSliderComponent",
  props: {
    items: Array,
  },
  data() {
    return {
      bannersSliderOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: false,
        clickable: false,
        preloadImages: true,
        loop: true,
        breakpoints: {
          540: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          769: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  computed: {
    isMobile() {
      if (typeof window !== "undefined") {
        return window.innerWidth <= 768;
      }
      return false;
    },
  },
  components: { SliderComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.banners-slider {
  &__img {
    ratio(1300, 346)
    +below(768px) {
      ratio(368, 346)
    }

    img {
      border-radius var(--big-radius)
    }
  }
}
</style>
