<template>
  <main class="page">
    <BreadcrumbsComponent :links="breadcrumbs" :title="share.title" />
    <div class="container-padding shares-open-page">
      <SharesDescriptionComponent :share="share" />
      <PromocodeComponent />
      <ProductsListComponent title="Что входит в акцию" :items="products" wrapper />
      <BannersSliderComponent :items="banners" />
      <ProductsListComponent :items="products" wrapper />
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import PromocodeComponent from "components/PromocodeComponent.vue";
import SharesDescriptionComponent from "../components/SharesDescriptionComponent.vue";
import ProductsListComponent from "components/ProductsListComponent.vue";
import SHARES_ITEM_PAGE from "@/graphql/pages/SharesItemPage.graphql";
import BannersSliderComponent from "components/slider/BannersSliderComponent.vue";
export default {
  name: "open",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: SHARES_ITEM_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.state.shares_item = data.share || {};
        store.state.shares_item.banners = data.banners || [];
        store.state.shares_item.products = data.share?.products || [];
      });
  },
  data() {
    return {
      breadcrumbs: [{ title: "Акции", route: { name: "shares" } }],
      sharesSliderOptions: {
        slidesPerView: 1,
        // spaceBetween: 30,
        pagination: false,
        clickable: false,
        preloadImages: true,
        loop: true,
      },
    };
  },
  computed: {
    share() {
      return this.$store.state.shares_item;
    },
    banners() {
      return this.$store.state.shares_item.banners;
    },
    products() {
      return this.$store.state.shares_item.products;
    },
  },
  components: {
    BannersSliderComponent,
    ProductsListComponent,
    SharesDescriptionComponent,
    PromocodeComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.shares-open-page{
  display flex
  flex-direction column
  gap 50px

  .slider-arrows__item--right{
    margin-right: 25px;
  }
  .slider-arrows__item--left{
    margin-left: 25px;
  }
  +below(1024px){
    gap 30px
  }
}
</style>
