<template>
  <div
    class="promo-code"
    :class="{
      'promo-code--changed': hideBenefits || hidePromo,
      'promo-code--big': big,
      'promo-code--gray': grayPromo,
    }"
  >
    <div v-if="!hideBenefits" class="promo-code__benefits">
      <h2 v-if="hidePromo" class="promo-code__benefits-title">
        Доставка продуктов <br />
        под все случаи жизни
      </h2>
      <div v-for="(item, i) in advantages || benefits" :key="i" class="promo-code__benefits-item">
        <IconComponent v-if="item.iconName" :name="item.iconName" />
        <ImgComponent v-else class="promo-code__benefits-icon" :img="item.icon" />
        <div class="promo-code__benefits-item_text">
          <h4>{{ item.title }}</h4>
          <span>{{ item.value }}</span>
        </div>
      </div>
    </div>
    <div v-if="!hidePromo" class="promo-code__input">
      <span class="promo-code__input-title">У вас есть промокод на скидку?</span>
      <LoadingIndicator v-if="loadingPromocode" title="Подождите.." />
      <div v-else class="promo-code__input-btn">
        <InputComponent
          v-model.trim="promocode.value"
          :error="promocode.error"
          placeholder="Введите ваш промокод"
          white
        />
        <button
          v-if="!activePromocode"
          :class="{ 'btn--white-hollow': !grayPromo, 'btn--green-hollow': grayPromo }"
          @click="checkPromocode"
          class="btn btn--lg"
        >
          Применить
        </button>
        <button
          v-if="activePromocode"
          :class="{ 'btn--white-hollow': !grayPromo, 'btn--red-hollow': grayPromo }"
          @click="removePromocode"
          class="btn btn--red btn--lg"
        >
          Удалить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SHARE_PROMOCODE from "@/graphql/queries/share_promocode.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "PromocodeComponent",
  props: {
    showTitle: Boolean,
    hidePromo: Boolean,
    hideBenefits: Boolean,
    big: Boolean,
    grayPromo: Boolean,
    advantages: Array,
  },
  data() {
    return {
      activePromocode: undefined,
      loadingPromocode: false,
      promocode: {
        value: undefined,
        error: undefined,
      },
    };
  },
  computed: {
    benefits() {
      return [
        {
          title: "Доставка 0 ₽",
          value: `за 30 мин. при заказе от ${this.feedback.free_delivery_price} ₽`,
          iconName: "bicycle",
        },
        { title: "Приятный взгревчик ", value: "к каждому заказу от Финик Маркет", iconName: "gift" },
      ];
    },
    feedback() {
      return this.$store.state.global.feedback || {};
    },
  },
  methods: {
    async checkPromocode() {
      if (!this.loadingPromocode && this.promocode.value) {
        this.promocode.error = undefined;
        this.loadingPromocode = true;
        await this.$apollo.provider.defaultClient
          .query({
            query: SHARE_PROMOCODE,
            variables: {
              promocode: this.promocode.value,
            },
          })
          .then(({ data }) => {
            this.loadingPromocode = false;
            // извините за этот код пожалуйста
            if (data.share_promocode) {
              let promoProducts = data.share_promocode.products.map((p) => p.id);
              let cartProducts = this.$store.state.auth.cart.map((c) => c.item?.id);
              let productStock = false;
              cartProducts.forEach((item) => {
                promoProducts.includes(item) ? (productStock = true) : false;
              });
              if (productStock) {
                this.activePromocode = this.promocode.value;
                this.$emit("setPromocode", data.share_promocode, this.promocode.value);
                this.$notify({
                  title: "Успешно",
                  text: "Промокод активирован",
                  type: "success",
                });
              } else {
                this.promocode.error = "В корзине отсутствуют товары для данного промокода";
              }
            } else {
              this.promocode.error = "Неверный промокод";
            }
          })
          .catch(() => {
            this.loadingPromocode = false;
            this.promocode.error = "Неверный промокод";
          });
      }
    },
    removePromocode() {
      this.promocode.value = undefined;
      this.promocode.error = undefined;
      this.activePromocode = undefined;
      this.$emit("removePromocode");
    },
  },
  components: { LoadingIndicator, InputComponent, IconComponent },
};
</script>

<style lang="stylus">
.promo-code{
  display grid
  gap 30px
  grid-template-columns: 857fr 413fr;
  +below(1024px){
    grid-template-columns 1fr
    gap 13px
  }

  &--changed {
    grid-template-columns 1fr
  }

  &--big {
    .promo-code__benefits {
      display grid
      grid-template-columns 500fr 345fr 345fr
      +below(990px) {
        grid-template-columns 1fr
      }
    }

    .promo-code__input {
      flex-direction row
      align-items center
      +below(860px) {
        flex-direction column
        align-items stretch
      }

      &-title {
        font-weight: 900;
        font-size: 1.75em
        line-height: 34px;
        text-transform uppercase
        max-width 368px
        +below(540px) {
          font-size: 1.25em
          line-height: 24px;
        }
      }

      &-btn,
      .input {
        flex-grow 1
      }
    }
  }

  &--gray &__input {
    background var(--gray)

    &-title {
      color var(--dark)
    }

    .btn {
      color var(--green)

      &:hover {
        color var(--white)
      }

      &--red {
        color var(--red)
      }
    }
  }

  &__benefits {
    display flex
    background: var(--gray);
    border-radius: 20px;
    padding 16px 30px
    gap 20px
    +below(1024px) {
      flex-direction column
      gap 15px
      padding 20px
    }
    +below(340px) {
      padding 15px
    }

    &-title {
      flex-shrink 0
      margin 0
      color var(--green-dark)
      display flex
      align-items center
    }
  }

  &__benefits-item {
    display flex
    align-items center
    background var(--white)
    border-radius: 15px;
    padding 19px 29px
    width 100%
    gap 20px
    +below(440px) {
      padding 18px 25px
    }
    +below(340px) {
      padding 15px
    }

    svg{
      max-width: 40px;
      max-height: 40px;
    }

    &_text{
      display flex
      flex-direction column
      h4{
        margin 0
        font-weight: 700;
        font-size: 1.125em;
        line-height: 26px;
        color: var(--dark);

        +below(1024px){
          font-size: 1em;
          line-height: 24px;
        }
      }
      span{
        font-weight: 400;
        font-size: 0.875em;
        line-height: 20px;
        color: var(--dark);
      }
    }
  }

  &__benefits-icon {
    max-width 40px
    max-height 40px
  }

  &__input{
    display flex
    flex-direction column
    background: var(--green);
    border-radius: 20px;
    gap 30px
    padding 20px 30px
    +below(1024px){
      padding 15px
    }

    &-title {
      font-weight: 700;
      font-size: 1.375em;
      line-height: 26px;
      color: var(--white);
      +below(1024px){
        font-size: 1.125em;
      }
    }
    &-btn{
      display flex
      gap 15px

      +below(1024px){
        flex-direction column
      }
    }
  }
}
</style>
