<template>
  <div class="products" :class="{ wrapper }">
    <h2 v-if="title">{{ title }}</h2>
    <div class="products-list">
      <ProductCardComponent v-for="(a, i) in items" :key="i" :data="a" />
    </div>
  </div>
</template>

<script>
import ProductCardComponent from "components/product/ProductCardComponent.vue";

export default {
  name: "ProductsListComponent",
  components: { ProductCardComponent },
  props: {
    title: String,
    items: Array,
    wrapper: Boolean,
  },
};
</script>

<style lang="stylus">
.products {
  &-list {
    display grid
    grid-template-columns repeat(auto-fill, minmax(250px, 1fr))
    grid-gap var(--gap)
    +below(1200px) {
      grid-template-columns repeat(auto-fill, minmax(200px, 1fr))
    }
    +below(540px) {
      grid-template-columns repeat(auto-fill, minmax(170px, 1fr))
    }
  }
}
</style>
